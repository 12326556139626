<template>
  <ui-message
    @messageClose="messageClose"
    :message="message"
    :messageProp="messageProp"
  />
</template>

<script>
import { mapActions } from "vuex";
import UiMessage from "../components/ui/ui-message.vue";

export default {
  name: "Redirect",
  components: { UiMessage },
  created() {
    if(this.$route.query.token) {
      console.log('12312')
      this.tokenHandler();
    } else if(this.$route.query.tokenlk) {
      this.tokenLk()
    } else {
      console.log('12312')
      this.authHandler();
    }
  },
  methods: {
    ...mapActions(["GET_TOKEN", "GET_TOKEN_REFRESH"]),

    authHandler() {
      this.axios
        .post("/api/login_check", {
          username: this.$route.query.username,
          password: this.$route.query.password
        })
        .then((response) => {
          this.GET_TOKEN(response.data.token);
          this.GET_TOKEN_REFRESH(response.data.refresh_token);
          this.$router.push("/");
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status === 401) {
            (this.message = true),
              (this.messageProp = {
                class: "alert__danger",
                title: "Неверные данные для входа",
                text: "Вернитесь назад"
              });
          }
        });
    },
    tokenHandler() {
      this.axios
        .get(`/driver/request/token?token=${this.$route.query.token}`)
        .then((response) => {
          this.GET_TOKEN(response.data.response);
          this.GET_TOKEN_REFRESH(response.data.response);
          this.$router.push("/");
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status === 401) {
            (this.message = true),
              (this.messageProp = {
                class: "alert__danger",
                title: "Неверные данные для входа",
                text: "Вернитесь назад"
              });
          }
        });
    },
    tokenLk() {
      this.GET_TOKEN(this.$route.query.tokenlk);
      this.GET_TOKEN_REFRESH(this.$route.query.refresh_token);
      this.$router.push("/");
    },

    messageClose() {
      this.message = false;
      this.messageProp = {};
    }
  }
};
</script>
